<template>
<v-app>
    <SingleAppBar title="บันทึกออกซิเจนในเลือด" :backward="true" name="healthrecord"/>
    <v-main class="natural lighten-4 fill-height">
      <v-container>
          <v-row>
              <v-col cols="12">
                  <v-card flat color="transparent">
                      <v-card-text>  
                        <v-form ref="form" v-model="valid" lazy-validation>
<TextField v-model="spo2" v-mask="'###'" :title="'ความเข้มข้นของออกซิเจนในเลือด'" placeholder="%" required :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"/>
<TextField v-model="pulse" v-mask="'###'" :title="'อัตราการเต้นของหัวใจ'" placeholder="bpm" required :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"/>
<DatePicker v-model="date" :title="'วันที่'" />
<TimePicker v-model="time" :title="'เวลา'"/>
<TextField v-model="note" :title="'Note'"/>
                        </v-form>
 <v-btn block large color="primary text-title-2" @click="postVitalSign()">ยืนยันการบันทึก</v-btn>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
        <!-- <ListCard/> -->
      </v-container>
       <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
      </v-main
    >
</v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
// import {postVitalSign} from "@/api/"
import { postVSign } from "@/api/cms"
import TextField from "@/components/healthrecord/TextField";
import TimePicker from "@/components/common/TimePicker";
import DatePicker from "@/components/common/DatePicker";
import { mask } from "vue-the-mask";
//import ListCard from "@/components/inbox/ListCard";

export default {
  name: "SaveBPM",
  directives: { mask },
  components: {
    SingleAppBar,
    TextField,
     TimePicker,
    DatePicker,
    //ListCard
  },
  data() {
    return {
      valid: false,
      state: false,
      items: [],
      loading: false,
      role: null,
      spo2: null,
      pulse: null,
      note: '',
      time: this.$dayjs(new Date()).format("HH:mm"),
      date: this.$dayjs(new Date()).format("YYYY-MM-DD"),
      
    }
  },
  methods: {
   postVitalSign () {
      this.loading = true
      const site = this.$offlineStorage.get("site");
      if(site && site.groupId) {
        let data = {
          blood_pressure:null,
          temperature: null,
          body_component: null,
          glucose:  null,
          oxymetry: [{
              device_timestamp: this.$dayjs(this.date + ' '+ this.time).unix(),
              spo2: Number(this.spo2),
              pr: Number(this.pulse),
              remark: (this.note) ? this.note : ''
            }]
        }
        postVSign(data, message=> {
          if (message.data){
            this.$router.push({name:'healthrecord'})
          }
        },error=>{
          console.log(error)
        })
      }
    },

    async fetchData(){
    //   this.role = this.$offlineStorage.get('role')
    //   const site = this.$offlineStorage.get("site");
    //   const tenant = this.$offlineStorage.get("tenant");
    //   if (navigator.onLine){
    //        this.loading = true
    //     try{    
    //     let itemresp = await announcement({tenantId: tenant.tenantId ,siteId: site.siteId,offset:0,limit:100 })
    //     for(let i =0 ;i<itemresp.data.result.length;i++){
    //       let resp = await announcementBanner({tenantId: tenant.tenantId ,siteId: site.siteId,bannerName: itemresp.data.result[i].bannerName})
    //       itemresp.data.result[i].img = resp.data.result
    //     }
    //     this.items = itemresp.data.result
    //     }catch(err){
    //       console.log(err)
    //     }finally{
    //       this.loading = false
    //     }
    //   }else{
    //      this.loading = true
    //      this.items =  this.$offlineStorage.get('announcement')
    //      this.loading = false
    //   }
    
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
};
</script>

<style lang="scss" scoped>

</style>